import {config} from "../../appConfig";

export const pageView = (url: string) => {
  // @ts-ignore
  window.gtag('config', config.googleAnalyticsKey, {
    page_path: url
  });
}

export const event = (desc: { action: string, params: string }) => {
  // @ts-ignore
  window.gtag('event', desc.action, desc.params);
}
