import {Col, Row} from 'reactstrap';
import React from 'react';
import {CustomCarousel} from '../components/CustomCarousel';

import image001 from '../public/img/slide_show/image_001.jpg';
import image002 from '../public/img/slide_show/image_002.jpg';
import image003 from '../public/img/slide_show/image_003.jpg';
import image004 from '../public/img/slide_show/image_004.jpg';
import image005 from '../public/img/slide_show/image_005.jpg';
import image006 from '../public/img/slide_show/image_006.jpg';
import image007 from '../public/img/slide_show/image_007.jpg';
import image008 from '../public/img/slide_show/image_008.jpg';
import image009 from '../public/img/slide_show/image_009.jpg';
import image010 from '../public/img/slide_show/image_010.jpg';
import image011 from '../public/img/slide_show/image_011.jpg';
import image012 from '../public/img/slide_show/image_012.jpg';
import image013 from '../public/img/slide_show/image_013.jpg';
import image014 from '../public/img/slide_show/image_014.jpg';
import image015 from '../public/img/slide_show/image_015.jpg';
import image016 from '../public/img/slide_show/image_016.jpg';
import image017 from '../public/img/slide_show/image_017.jpg';
import image018 from '../public/img/slide_show/image_018.jpg';
import image019 from '../public/img/slide_show/image_019.jpg';
import image020 from '../public/img/slide_show/image_020.jpg';
import image021 from '../public/img/slide_show/image_021.jpg';
import image022 from '../public/img/slide_show/image_022.jpg';
import image023 from '../public/img/slide_show/image_023.jpg';
import image024 from '../public/img/slide_show/image_024.jpg';
import image025 from '../public/img/slide_show/image_025.jpg';
import image026 from '../public/img/slide_show/image_026.jpg';
import image027 from '../public/img/slide_show/image_027.jpg';
import {event} from "../lib/ga";

const Page: React.FC = () => {
  return (
    <div>
      <h2>Willkommen</h2>
      <Row>
        <Col md={1}/>
        <Col md={10}>
          <CustomCarousel autoPlay={true}
                          dynamicHeight={true}
                          interval={5000}
                          infiniteLoop={true}
                          useKeyboardArrows={true}
                          showThumbs={false}
                          onClickItem={(index) => event({action: 'slide_click', params: `${index}`})}
                          onClickThumb={(index) => event({action: 'thumb_click', params: `${index}`})}
          >
            <img src={image001}/>
            <img src={image002}/>
            <img src={image003}/>
            <img src={image004}/>
            <img src={image005}/>
            <img src={image006}/>
            <img src={image007}/>
            <img src={image008}/>
            <img src={image009}/>
            <img src={image010}/>
            <img src={image011}/>
            <img src={image012}/>
            <img src={image013}/>
            <img src={image014}/>
            <img src={image015}/>
            <img src={image016}/>
            <img src={image017}/>
            <img src={image018}/>
            <img src={image019}/>
            <img src={image020}/>
            <img src={image021}/>
            <img src={image022}/>
            <img src={image023}/>
            <img src={image024}/>
            <img src={image025}/>
            <img src={image026}/>
            <img src={image027}/>
          </CustomCarousel>
        </Col>
      </Row>
    </div>
  );
};

export default Page;
